<script>
import Multiselect from "vue-multiselect";
import {
  required,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";
import { typeOfAccountData } from "@/data/data-type-of-account";
import InputTaxNumber from "@/components/form-elements/input-tax-number"
import Repository from "@/app/repository/repository-factory";

const ApplicantsCompanyRepository = Repository.get(
  "ApplicantsCompanyRepository"
);
const PaymentCompanyRepository = Repository.get("PaymentCompanyRepository");

export default {
  name: "companyDataStep",
  props: {
    clonedData: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
  },
  components: { Multiselect, InputTaxNumber },
  data() {
    return {
      inputLoading: false,
      notDefinedCurrentAccount: false,
      companyId: "",
      companySelectOptionsField: [],
      companyAccountSelectOptionsField: typeOfAccountData,
      companySelectValueField: null,
      companyForm: {
        companyId: "",
        companyNameField: "",
        companyAddressField: "",
        companyTaxNumberField: "",
        companyAccountSelectValueField: null,
        companyBusinessUnitCode: "",
      },
    };
  },
  validations: {
    companyForm: {
      companyId: {
        required,
      },
      companyNameField: {
        required,
        maxLength: maxLength(255),
        minLength: minLength(3),
      },
      companyAddressField: {
        required,
        maxLength: maxLength(255),
        minLength: minLength(3),
      },
      companyAccountSelectValueField: {
        required,
      },
    },
  },
  created() {
    this.getCompanies();
  },
  methods: {
    validate() {
      this.$v.companyForm.$touch();
      this.$refs.InputTaxNumber.$v.$touch();
      var isValid = !this.$v.companyForm.$invalid && !this.$refs.InputTaxNumber.$v.$invalid;
      this.$emit("on-validate", this.$data.companyForm, isValid);
      return isValid;
    },
    dispatchAction(el) {
      this.companyId = el.companyId;
      this.companyForm.companyId = el.companyId;
      this.companyForm.companyNameField = el.name;
      this.companyForm.companyAddressField = el.address;
      this.companyForm.companyTaxNumberField = el.nip;
      this.companyForm. companyBusinessUnitCode = el.businessUnitCode;
    },
    companyLabel({ name, nip, address }) {
      return `${name} ${nip} ${address}`;
    },
    accountAndTaxLabel({ name, description }) {
      return `${name} ${description}`;
    },
    getCompanies() {
      this.inputLoading = true;
      ApplicantsCompanyRepository.getAll()
        .then((response) => {

          this.companySelectOptionsField = response.data.filter((obj) => {
            return obj.isActive === true;
          });

          if (this.clonedData !== null) {
            this.companySelectValueField = this.companySelectOptionsField.filter(
              (obj) => {
                return obj.companyId === this.clonedData.companyId;
              }
            );
            this.dispatchAction(this.companySelectValueField[0]);
          }
          this.inputLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.inputLoading = false;
        });
    },
    getBankTypes(companyId) {
      this.inputLoading = true;
      this.notDefinedCurrentAccount = false;
      PaymentCompanyRepository.getBankTypes(companyId)
        .then((response) => {
          let options = [];
          response.data.forEach((val) => {
            options.push(
              typeOfAccountData.find((type) => type.index === val.index)
            );
          });

          this.companyAccountSelectOptionsField = options;

          if (options[0].index === 1) {
            this.companyForm.companyAccountSelectValueField = options.length > 0 ? options[0] : null;
          } else {
            this.notDefinedCurrentAccount = true;
          }

          if (this.clonedData !== null) {
            let account = this.companyAccountSelectOptionsField.filter(
              (obj) => {
                return obj.name === this.clonedData.typeOfBankAccount;
              }
            );
            this.companyForm.companyAccountSelectValueField = account[0];
          }
          this.inputLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.inputLoading = false;
        });
    },
    getNipValue: function(params) {
      this.companyForm.companyTaxNumberField = params;
    }
  },
  watch: {
    companyId: function (newVal, oldVal) {
      if (newVal !== "") {
        this.companyForm.companyAccountSelectValueField = null;
        this.getBankTypes(newVal);
      }
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <b-alert variant="warning" class="mb-3" show fade v-if="notDefinedCurrentAccount">
        <i class="mdi mdi-information-outline mr-2"></i>Brak zdefiniowanego bieżącego rachunku bankowego dla wybranej spółki. Złożenie wniosku nie jest możliwe.
      </b-alert>
      <div class="form-group">
        <label for="companySelectValueField">
          Wybierz firmę
        </label>
        <multiselect id="companySelectValueField" v-model.trim="companySelectValueField" :options="companySelectOptionsField" @select="dispatchAction" :custom-label="companyLabel" track-by="nip" name="companySelectValueField" placeholder="Wybierz firmę z listy" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value :disabled="inputLoading">
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc"><span class="option__title">{{ props.option.name }}</span></span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.name }}</span><br />
              <span class="option__small small text-dark">{{ props.option.address }}</span>
            </div>
          </template>
          <template slot="clear">
            <div class="multiselect__loading"><i v-if="inputLoading" class="el-icon-loading mr-1"></i></div>
          </template>
          <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
        </multiselect>
      </div>
      <div class="form-group">
        <label for="companyNameField">
          Nazwa firmy
          <em class="text-danger">*</em>
        </label>
        <input id="companyNameField" v-model.trim="companyForm.companyNameField" @input="$v.companyForm.companyNameField.$touch()" class="form-control" :class="{ 'is-invalid': $v.companyForm.companyNameField.$error, 'is-valid': !$v.companyForm.companyNameField.$invalid && !$v.companyForm.companyNameField.$error }" type="text" name="companyNameField" placeholder="Wpisz nazwę firmy" value required disabled />
        <div v-if="$v.companyForm.companyNameField.$error" class="invalid-feedback">
          <span class="d-block" v-if="$v.companyForm.companyNameField.$error && !$v.companyForm.companyNameField.required">Pole jest wymagane!</span>
          <span class="d-block" v-if="$v.companyForm.companyNameField.$error && !$v.companyForm.companyNameField.maxLength">Przekroczona dozwolona ilość znaków!</span>
          <span class="d-block" v-if="$v.companyForm.companyNameField.$error && !$v.companyForm.companyNameField.minLength">Zbyt mała ilość znaków!</span>
        </div>
      </div>
      <div class="form-group">
        <label for="companyAddressField">
          Adres firmy
          <em class="text-danger">*</em>
        </label>
        <input id="companyAddressField" v-model.trim="companyForm.companyAddressField" @input="$v.companyForm.companyAddressField.$touch()" class="form-control" :class="{ 'is-invalid': $v.companyForm.companyAddressField.$error, 'is-valid': !$v.companyForm.companyAddressField.$invalid && !$v.companyForm.companyAddressField.$error }" type="text" name="companyAddressField" placeholder="Wpisz adres firmy" value required disabled />
        <div v-if="$v.companyForm.companyAddressField.$error" class="invalid-feedback">
          <span class="d-block" v-if="$v.companyForm.companyAddressField.$error && !$v.companyForm.companyAddressField.required">Pole jest wymagane!</span>
          <span class="d-block" v-if="$v.companyForm.companyAddressField.$error && !$v.companyForm.companyAddressField.maxLength">Przekroczona dozwolona ilość znaków!</span>
          <span class="d-block" v-if="$v.companyForm.companyAddressField.$error && !$v.companyForm.companyAddressField.minLength">Zbyt mała ilość znaków!</span>
        </div>
      </div>
      <InputTaxNumber
        @input-tax-number="getNipValue"
        :incomingValue="companyForm.companyTaxNumberField"
        :inputLabel="'NIP firmy'"
        ref="InputTaxNumber"
      />
      <!--
      <div class="form-group" :class="{ 'is-invalid' : $v.companyForm.companyAccountSelectValueField.$error }">
        <label for="companyAccountSelectValueField">
          Wybierz typ rachunku
          <em class="text-danger">*</em>
        </label>
        <multiselect id="companyAccountSelectValueField" v-model.trim="companyForm.companyAccountSelectValueField" :options="companyAccountSelectOptionsField" :custom-label="accountAndTaxLabel" track-by="index" @input="$v.companyForm.companyAccountSelectValueField.$touch()" :class="{ 'is-invalid': $v.companyForm.companyAccountSelectValueField.$error, 'is-valid': !$v.companyForm.companyAccountSelectValueField.$invalid && !$v.companyForm.companyAccountSelectValueField.$error }" name="companyAccountSelectValueField" placeholder="Wybierz typ rachunku z listy" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required :disabled="inputLoading">
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc"><span class="option__title">{{ props.option.description }}</span></span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.name }}</span><br />
              <span class="option__small small text-dark">{{ props.option.description }}</span>
            </div>
          </template>
          <template slot="clear">
            <div class="multiselect__loading"><i v-if="inputLoading" class="el-icon-loading mr-1"></i></div>
          </template>
          <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
        </multiselect>
        <template v-if="$v.companyForm.companyAccountSelectValueField.$error">
          <small class="text-danger">Pole jest wymagane!</small>
        </template>
      </div>
      -->
    </div>
  </div>
</template>