<script>
import Multiselect from "vue-multiselect";
import { required, helpers } from "vuelidate/lib/validators";
import moment from "moment";
import Repository from "@/app/repository/repository-factory";

const PrepaidCardRepository = Repository.get("PrepaidCardRepository");
const PaymentBankRepository = Repository.get("PaymentBankRepository");

const alphaNumAndSpecials = helpers.regex(
  "alphaNumAndSpecials",
  /^[A-Z0-9]*$/
);

export default {
  name: "cardDetailsDataStep",
  props: {
    companyId: {
      type: String,
      required: true,
    },
    clonedData: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
    bucAsUuid: {
      type: String,
      required: true,
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      inputLoading: false,
      requestErrorObj: null,
      bankName: "",
      prepaidCardSelectOptionsField: [],
      cardDetailsForm: {
        prepaidCardSelectValueField: null,
        prepaidCardChecked: false,
        checkedPrepaidCardData: null,
        bankAccountNumber: null,
      },
    };
  },
  validations: {
    cardDetailsForm: {
      prepaidCardSelectValueField: {
        required,
      },
      prepaidCardChecked: {
        required,
        fieldAccountChecked: (value) => {
          return value;
        },
      },
      bankAccountNumber: {
        required,
        alphaNumAndSpecials,
        accExist (value) {
          if (this.isAccNumExists) {
            return true
          }
          return false
        },
      }
    },
  },
  created() {
    this.getPrepaidCards();
    if (this.clonedData !== null) {
      let cardDetails = {
        bankId: this.clonedData.bankId,
      };

      this.dispatchAction(cardDetails);
    }
  },
  methods: {
    validate() {
      this.$v.cardDetailsForm.$touch();
      var isValid = !this.$v.cardDetailsForm.$invalid;
      this.$emit("on-validate", this.$data.cardDetailsForm, isValid);
      return isValid;
    },
    getPrepaidCards() {
      this.inputLoading = true;
      PrepaidCardRepository.getPrepaidCardsByCompanyId(this.companyId)
        .then((response) => {
          this.prepaidCardSelectOptionsField = response.data;
          if (this.clonedData !== null) {
            let selectedCard = this.prepaidCardSelectOptionsField.filter(
              (obj) => {
                return obj.prepaidCardId === this.clonedData.applicationSpecificData.prepaidCardId;
              }
            );
            if (selectedCard.length > 0) {
              this.cardDetailsForm.prepaidCardSelectValueField = selectedCard[0];
              this.cardDetailsForm.bankAccountNumber = selectedCard[0].bankAccountNumber;
              this.dispatchAction(selectedCard[0]);
            }
          }
          this.inputLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.inputLoading = false;
        });
    },
    getBankById(id) {
      PaymentBankRepository.get(id)
        .then((response) => {
          this.bankName = response.data.name;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    dispatchAction(el) {
      this.getBankById(el.bankId);
      this.cardDetailsForm.prepaidCardChecked = false;
      this.cardDetailsForm.checkedPrepaidCardData = null;
    },
    prepaidCardLabel({ cardNumber, nameOfCardholder, bankAccountNumber }) {
      return `${cardNumber} ${nameOfCardholder} ${bankAccountNumber}`;
    },
    checkPrepaidCard() {
      this.inputLoading = true;
      this.requestErrorObj = null;
      const reqCompany = this.bucAsUuid;
      const reqCardHolderNumber = this.cardDetailsForm.prepaidCardSelectValueField.cardholderNumber;
      const reqToday = moment(new Date()).format("YYYY-MM-DD");
      PrepaidCardRepository.checkCard("company=" + reqCompany + "&cardholderNumber=" + reqCardHolderNumber + "&date=" + reqToday )
        .then((response) => {
          this.cardDetailsForm.checkedPrepaidCardData = response.data;
          this.cardDetailsForm.prepaidCardChecked = true;
          this.inputLoading = false;
        })
        .catch((error) => {
          this.inputLoading = false;
          this.requestErrorObj = error;
          console.log(error);
        });
    },
    changesSelect(sc) {
      this.cardDetailsForm.prepaidCardSelectValueField = sc[0];
      this.dispatchAction(sc[0]);
    },
    clearSelect() {
      this.cardDetailsForm.prepaidCardChecked = false;
      this.cardDetailsForm.checkedPrepaidCardData = null;
      this.cardDetailsForm.prepaidCardSelectValueField = null;
    },
    supertrim(inp) {
      this.cardDetailsForm.bankAccountNumber = inp.replace(/\s/g, '');
      this.$v.cardDetailsForm.bankAccountNumber.$touch()
    }
  },
  computed: {
    isAccNumExists() {
      if (this.cardDetailsForm.bankAccountNumber && this.prepaidCardSelectOptionsField) {
        let sc = this.prepaidCardSelectOptionsField.filter(
          (obj) => {
            return obj.bankAccountNumber === this.cardDetailsForm.bankAccountNumber;
          }
        );
        if (sc.length > 0) {
          this.changesSelect(sc);
          return true
        }
        this.clearSelect();
        return false
      }
      return false;
    },
  },
  watch: {
    companyId: function (newVal, oldVal) {
      if (newVal !== "") {
        this.cardDetailsForm.prepaidCardChecked = false;
        this.cardDetailsForm.checkedPrepaidCardData = null;
        this.cardDetailsForm.prepaidCardSelectValueField = null;
        this.getPrepaidCards();
      }
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="form-group d-none">
        <label for="prepaidCardSelectValueField">
          Wybierz kartę <em class="text-danger">*</em>
        </label>
        <multiselect id="prepaidCardSelectValueField" v-model.trim="cardDetailsForm.prepaidCardSelectValueField" :options="prepaidCardSelectOptionsField" @select="dispatchAction" :custom-label="prepaidCardLabel" track-by="prepaidCardId" name="prepaidCardSelectValueField" placeholder="Wybierz kartę z listy" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required :disabled="inputLoading">
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc"><span class="option__title">{{ props.option.cardNumber }}</span></span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.cardNumber }}</span><br />
              <span class="option__small small text-dark">Właściciel {{ props.option.nameOfCardholder }} ważna do {{ props.option.expiryDate }}</span>
            </div>
          </template>
          <template slot="clear">
            <div class="multiselect__loading"><i v-if="inputLoading" class="el-icon-loading mr-1"></i></div>
          </template>
          <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
        </multiselect>
        <template v-if="$v.cardDetailsForm.prepaidCardSelectValueField.$error">
          <small class="text-danger">Pole jest wymagane!</small>
        </template>
      </div>

      <div class="form-group">
        <label for="providerAccountField">
          Numer rachunku karty
          <em class="text-danger">*</em>
        </label>
        <input id="providerAccountField" pattern="^\S+$" @keydown.space.prevent v-model.trim="cardDetailsForm.bankAccountNumber" @input="supertrim(cardDetailsForm.bankAccountNumber)" class="form-control" :class="{ 'is-invalid': $v.cardDetailsForm.bankAccountNumber.$error, 'is-valid': !$v.cardDetailsForm.bankAccountNumber.$invalid && !$v.cardDetailsForm.bankAccountNumber.$error }" type="text" name="providerAccountField" placeholder="Wpisz numer rachunku karty" value required :disabled="inputLoading" />
        <div v-if="$v.cardDetailsForm.bankAccountNumber.$error" class="invalid-feedback">
          <span class="d-block" v-if="$v.cardDetailsForm.bankAccountNumber.$error && !$v.cardDetailsForm.bankAccountNumber.required">Pole jest wymagane!</span>
          <span class="d-block" v-if="$v.cardDetailsForm.bankAccountNumber.$error && !$v.cardDetailsForm.bankAccountNumber.accExist">Niepoprawny numer konta lub nie zostały dopełnione formalności dotyczące dokumentacji karty!</span>
          <span class="d-block" v-if="$v.cardDetailsForm.bankAccountNumber.$error && !$v.cardDetailsForm.bankAccountNumber.alphaNumAndSpecials">Wpisano niedozwolone znaki lub spację!</span>
        </div>
      </div>

      <b-alert variant="warning" class="mb-3" show fade v-if="cardDetailsForm.prepaidCardSelectValueField && $v.cardDetailsForm.prepaidCardChecked.$error">
        <i class="mdi mdi-shield-alert-outline mr-2"></i>Aby konynuować, należy sprawdzić rozliczenie karty!
      </b-alert>

      <button class="btn btn-success btn-block my-3" v-if="cardDetailsForm.prepaidCardSelectValueField" @click="checkPrepaidCard" :disabled="cardDetailsForm.prepaidCardSelectValueField == null || inputLoading">
        <i v-if="inputLoading" class="el-icon-loading mr-1"></i>
        <i v-else class="el-icon-circle-check mr-1"></i>
        Sprawdź rozliczenie karty
      </button>

      <b-alert variant="success" class="mb-3" show fade v-if="cardDetailsForm.checkedPrepaidCardData && cardDetailsForm.checkedPrepaidCardData.isSettled">
        <i class="mdi mdi-shield-alert-outline mr-2"></i>Karta jest rozliczona!
        <br /><small>Na karcie nie istnieją nierozliczone transakcje.</small>
      </b-alert>

      <b-alert variant="danger" class="mb-3" show fade v-else-if="cardDetailsForm.checkedPrepaidCardData && !cardDetailsForm.checkedPrepaidCardData.isSettled">
        <i class="mdi mdi-shield-alert-outline mr-2"></i><strong>Uwaga!</strong> Karta nie jest rozliczona!
        <br />
        <small v-if="cardDetailsForm.checkedPrepaidCardData.balance">Na karcie istnieją nierozliczone transakcje na kwotę {{ cardDetailsForm.checkedPrepaidCardData.balance }}.</small>
        <small v-else>Na karcie istnieją nierozliczone transakcje. Brak informacji o wysokości nierozliczonej kwoty.</small>
      </b-alert>

      <b-alert variant="danger" class="mb-3" show fade v-if="requestErrorObj">
        <i class="mdi mdi-shield-alert-outline mr-2"></i><strong>Uwaga!</strong> Wystąpił błąd!
        <br /><small>{{ requestErrorObj.response.data.message }}</small>
      </b-alert>

      <!-- <div class="table-responsive" v-if="cardDetailsForm.checkedPrepaidCardData && cardDetailsForm.checkedPrepaidCardData.transactions">
        <div data-simplebar class="conversation-list px-0">
          <div class="simplebar-scroll-content">
            <div class="simplebar-content">
              <table class="table table-sm mb-0">
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Nr</th>
                    <th>Tytuł</th>
                    <th>Data</th>
                    <th>Kwota</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(sData, index) in cardDetailsForm.checkedPrepaidCardData.transactions" :key="index">
                    <td>&nbsp;</td>
                    <td class="align-middle" scope="row">
                      {{ index + 1 }}
                    </td>
                    <td class="align-middle">
                      {{ sData.name }}
                    </td>
                    <td class="align-middle">{{ sData.date }}</td>
                    <td>{{ sData.amount }} PLN</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> -->

      <div class="ccard" v-if="cardDetailsForm.prepaidCardSelectValueField && cardDetailsForm.prepaidCardSelectValueField.cardNumber && cardDetailsForm.checkedPrepaidCardData">
        <div class="bank-name">{{ bankName }}</div>
        <div class="chip">
          <div class="side left"></div>
          <div class="side right"></div>
          <div class="vertical top"></div>
          <div class="vertical bottom"></div>
        </div>
        <div class="data">
          <div class="pan">{{ cardDetailsForm.prepaidCardSelectValueField.cardNumber | formatCreditCardNumber  }}</div>
          <div class="first-digits">{{ cardDetailsForm.prepaidCardSelectValueField.cardType.name }}</div>
          <div class="exp-date-wrapper">
            <div class="left-label">EXPIRES END</div>
            <div class="exp-date">
              <div class="upper-labels">MONTH/YEAR</div>
              <div class="date" :title="cardDetailsForm.prepaidCardSelectValueField.expiryDate">{{ cardDetailsForm.prepaidCardSelectValueField.expiryDate }}</div>
            </div>
          </div>
          <div class="name-on-card" :title="cardDetailsForm.prepaidCardSelectValueField.nameOfCardholder">{{ cardDetailsForm.prepaidCardSelectValueField.nameOfCardholder }}</div>
        </div>
        <div class="lines-down"></div>
        <div class="lines-up"></div>
      </div>
    </div>
  </div>
</template>

<style>
.ccard {
  position: relative;
  margin: auto;
  width: 85.6mm;
  height: 53.98mm;
  color: #fff;
  /* font: 22px/1 "Iceland", monospace; */
  font-size: 12px;
  background: #adb5bd;
  border: 1px solid #96a1ac;
  -webkit-border-radius: 10px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 10px;
  -moz-background-clip: padding;
  border-radius: 10px;
  background-clip: padding-box;
  overflow: hidden;
}
.bank-name {
  float: right;
  margin-top: 15px;
  margin-right: 30px;
  /* font: 800 14px "Open Sans", Arial, sans-serif; */
  font-size: 12px;
}
.chip {
  position: relative;
  z-index: 49;
  width: 50px;
  height: 40px;
  margin-top: 50px;
  margin-bottom: 10px;
  margin-left: 30px;
  background: #fffcb1;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #fffcb1 0%, #b4a365 100%);
  /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    color-stop(0%, #fffcb1),
    color-stop(100%, #b4a365)
  );
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(-45deg, #fffcb1 0%, #b4a365 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(-45deg, #fffcb1 0%, #b4a365 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(-45deg, #fffcb1 0%, #b4a365 100%);
  /* IE10+ */
  background: linear-gradient(135deg, #fffcb1 0%, #b4a365 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fffcb1", endColorstr="#b4a365", GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  border: 1px solid #322d28;
  -webkit-border-radius: 10px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 10px;
  -moz-background-clip: padding;
  border-radius: 10px;
  background-clip: padding-box;
  -webkit-box-shadow: 0 1px 2px #322d28,
    0 0 5px 0 0 5px rgba(144, 133, 87, 0.25) inset;
  -moz-box-shadow: 0 1px 2px #322d28,
    0 0 5px 0 0 5px rgba(144, 133, 87, 0.25) inset;
  box-shadow: 0 1px 2px #322d28, 0 0 5px 0 0 5px rgba(144, 133, 87, 0.25) inset;
  overflow: hidden;
}
.chip .side {
  position: absolute;
  top: 8px;
  width: 12px;
  height: 24px;
  border: 1px solid #322d28;
  -webkit-box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset,
    0 0 5px rgba(144, 133, 87, 0.25), 0 0 4px rgba(0, 0, 0, 0.1),
    0 0 4px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset,
    0 0 5px rgba(144, 133, 87, 0.25), 0 0 4px rgba(0, 0, 0, 0.1),
    0 0 4px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset,
    0 0 5px rgba(144, 133, 87, 0.25), 0 0 4px rgba(0, 0, 0, 0.1),
    0 0 4px rgba(0, 0, 0, 0.1) inset;
}
.chip .side.left {
  left: 0;
  border-left: none;
  -webkit-border-radius: 0 2px 2px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 2px 2px 0;
  -moz-background-clip: padding;
  border-radius: 0 2px 2px 0;
  background-clip: padding-box;
}
.chip .side.right {
  right: 0;
  border-right: none;
  -webkit-border-radius: 2px 0 0 2px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 2px 0 0 2px;
  -moz-background-clip: padding;
  border-radius: 2px 0 0 2px;
  background-clip: padding-box;
}
.chip .side:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: inline-block;
  width: 100%;
  height: 0px;
  margin: auto;
  border-top: 1px solid #322d28;
  -webkit-box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset,
    0 0 5px rgba(144, 133, 87, 0.25), 0 0 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset,
    0 0 5px rgba(144, 133, 87, 0.25), 0 0 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset,
    0 0 5px rgba(144, 133, 87, 0.25), 0 0 4px rgba(0, 0, 0, 0.1);
}
.chip .vertical {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 8.66666667px;
  height: 12px;
  border: 1px solid #322d28;
  -webkit-box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset,
    0 0 5px rgba(144, 133, 87, 0.25), 0 0 4px rgba(0, 0, 0, 0.1),
    0 0 4px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset,
    0 0 5px rgba(144, 133, 87, 0.25), 0 0 4px rgba(0, 0, 0, 0.1),
    0 0 4px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset,
    0 0 5px rgba(144, 133, 87, 0.25), 0 0 4px rgba(0, 0, 0, 0.1),
    0 0 4px rgba(0, 0, 0, 0.1) inset;
}
.chip .vertical.top {
  top: 0;
  border-top: none;
}
.chip .vertical.top:after {
  top: 12px;
  width: 17.33333333px;
}
.chip .vertical.bottom {
  bottom: 0;
  border-bottom: none;
}
.chip .vertical.bottom:after {
  bottom: 12px;
}
.chip .vertical:after {
  content: "";
  position: absolute;
  left: -8.66666667px;
  display: inline-block;
  width: 26px;
  height: 0px;
  margin: 0;
  border-top: 1px solid #322d28;
  -webkit-box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset,
    0 0 5px rgba(144, 133, 87, 0.25), 0 0 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset,
    0 0 5px rgba(144, 133, 87, 0.25), 0 0 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset,
    0 0 5px rgba(144, 133, 87, 0.25), 0 0 4px rgba(0, 0, 0, 0.1);
}
.data {
  position: relative;
  z-index: 49;
  margin-left: 30px;
  text-transform: uppercase;
}
.data .pan,
.data .month,
.data .year,
.data .year:before,
.data .name-on-card,
.data .date {
  position: relative;
  z-index: 20;
  letter-spacing: 1px;
  text-shadow: 1px 1px 1px #000;
}
.data .pan:before,
.data .month:before,
.data .year:before,
.data .year:before:before,
.data .name-on-card:before,
.data .date:before,
.data .pan:after,
.data .month:after,
.data .year:after,
.data .year:before:after,
.data .name-on-card:after,
.data .date:after {
  position: absolute;
  z-index: -10;
  content: attr(title);
  color: rgba(0, 0, 0, 0.2);
  text-shadow: none;
}
.data .pan:before,
.data .month:before,
.data .year:before,
.data .year:before:before,
.data .name-on-card:before,
.data .date:before {
  top: -1px;
  left: -1px;
  color: rgba(0, 0, 0, 0.1);
}
.data .pan:after,
.data .month:after,
.data .year:after,
.data .year:before:after,
.data .name-on-card:after,
.data .date:after {
  top: 1px;
  left: 1px;
  z-index: 10;
}
.data .pan {
  position: relative;
  z-index: 49;
  margin: 0;
  letter-spacing: 1px;
  font-size: 20px;
}
.data .first-digits {
  margin: 0;
  /* font: 400 10px/1 "Open Sans", Arial, sans-serif; */
  font-size: 10px;
}
.data .exp-date-wrapper {
  margin-top: 5px;
  margin-left: 64px;
  line-height: 1;
  *zoom: 1;
}
.data .exp-date-wrapper:before,
.data .exp-date-wrapper:after {
  content: " ";
  display: table;
}
.data .exp-date-wrapper:after {
  clear: both;
}
.data .exp-date-wrapper .left-label {
  float: left;
  display: inline-block;
  width: 40px;
  /* font: 400 7px/8px "Open Sans", Arial, sans-serif; */
  font-size: 7px;
  letter-spacing: 0.5px;
}
.data .exp-date-wrapper .exp-date {
  display: inline-block;
  float: left;
  margin-top: -10px;
  margin-left: 10px;
  text-align: center;
}
.data .exp-date-wrapper .exp-date .upper-labels {
  /* font: 400 7px/1 "Open Sans", Arial, sans-serif; */
  font-size: 7px;
}
.data .exp-date-wrapper .exp-date .year:before {
  content: "/";
}
.data .name-on-card {
  margin-top: 10px;
}
.lines-down:before {
  content: "";
  position: absolute;
  top: 80px;
  left: -200px;
  z-index: 10;
  width: 550px;
  height: 400px;
  border-top: 2px solid#e63946;
  -webkit-border-radius: 40% 60% 0 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 40% 60% 0 0;
  -moz-background-clip: padding;
  border-radius: 40% 60% 0 0;
  background-clip: padding-box;
  box-shadow: 1px 1px 100px #e63946;
  background: #e63946;
  /* Old browsers */
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 100%,
    #e63946 100%
  );
  /* FF3.6+ */
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, rgba(45, 33, 166, 0)),
    color-stop(100%, #e63946)
  );
  /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 100%,
    #e63946 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 44%,
    #e63946 100%
  );
  /* Opera 12+ */
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 44%,
    #e63946 100%
  );
  /* IE10+ */
  background: radial-gradient(
    ellipse at center,
    rgba(45, 33, 166, 0) 44%,
    #e63946 100%
  );
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(45, 33, 166, 0)", endColorstr="#2d21a6", GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}
.lines-down:after {
  content: "";
  position: absolute;
  top: 20px;
  left: -100px;
  z-index: 10;
  width: 350px;
  height: 400px;
  border-top: 2px solid#e63946;
  -webkit-border-radius: 20% 80% 0 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 20% 80% 0 0;
  -moz-background-clip: padding;
  border-radius: 20% 80% 0 0;
  background-clip: padding-box;
  box-shadow: inset -1px -1px 44px #e63946;
  background: #e63946;
  /* Old browsers */
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 100%,
    #e63946 100%
  );
  /* FF3.6+ */
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, rgba(45, 33, 166, 0)),
    color-stop(100%, #e63946)
  );
  /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 100%,
    #e63946 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 44%,
    #e63946 100%
  );
  /* Opera 12+ */
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 44%,
    #e63946 100%
  );
  /* IE10+ */
  background: radial-gradient(
    ellipse at center,
    rgba(45, 33, 166, 0) 44%,
    #e63946 100%
  );
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(45, 33, 166, 0)", endColorstr="#2d21a6", GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}
.lines-up:before {
  content: "";
  position: absolute;
  top: -110px;
  left: -70px;
  z-index: 2;
  width: 480px;
  height: 300px;
  border-bottom: 2px solid#e63946;
  -webkit-border-radius: 0 0 60% 90%;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 0 60% 90%;
  -moz-background-clip: padding;
  border-radius: 0 0 60% 90%;
  background-clip: padding-box;
  box-shadow: inset 1px 1px 44px #e63946;
  background: #e63946;
  /* Old browsers */
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(64, 49, 178, 0) 100%,
    #e63946 100%
  );
  /* FF3.6+ */
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, rgba(64, 49, 178, 0)),
    color-stop(100%, #e63946)
  );
  /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(64, 49, 178, 0) 100%,
    #e63946 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(
    center,
    ellipse cover,
    rgba(64, 49, 178, 0) 44%,
    #e63946 100%
  );
  /* Opera 12+ */
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    rgba(64, 49, 178, 0) 44%,
    #e63946 100%
  );
  /* IE10+ */
  background: radial-gradient(
    ellipse at center,
    rgba(64, 49, 178, 0) 44%,
    #e63946 100%
  );
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(64, 49, 178, 0)", endColorstr="#e63946", GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}
.lines-up:after {
  content: "";
  position: absolute;
  top: -180px;
  left: -200px;
  z-index: 1;
  width: 530px;
  height: 420px;
  border-bottom: 2px solid #e63946;
  -webkit-border-radius: 0 40% 50% 50%;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 40% 50% 50%;
  -moz-background-clip: padding;
  border-radius: 0 40% 50% 50%;
  background-clip: padding-box;
  box-shadow: inset 1px 1px 44px #e63946;
  background: #e63946;
  /* Old browsers */
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 100%,
    #e63946 100%
  );
  /* FF3.6+ */
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, rgba(45, 33, 166, 0)),
    color-stop(100%, #e63946)
  );
  /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 100%,
    #e63946 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 44%,
    #e63946 100%
  );
  /* Opera 12+ */
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 44%,
    #e63946 100%
  );
  /* IE10+ */
  background: radial-gradient(
    ellipse at center,
    rgba(45, 33, 166, 0) 44%,
    #e63946 100%
  );
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(45, 33, 166, 0)", endColorstr="#2d21a6", GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}
</style>
