<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { authComputed } from "@/state/helpers";
import { roleService } from "@/app/service/roleService";
import Layout from "@/router/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Preloader from "@/components/preloader";
import prepaidCardApplicantDataStep from "./steps/prepaid-card-applicant-data-step";
import prepaidCardCompanyDataStep from "./steps/prepaid-card-company-data-step";
import prepaidCardDetailsDataStep from "./steps/prepaid-card-details-data-step";
import prepaidCardTransferDataStep from "./steps/prepaid-card-transfer-data-step";
import prepaidCardSummaryDataStep from "./steps/prepaid-card-summary-data-step";
import TransferInfoSidebar from "@/components/transfer/transfer-info-sidebar";
import Repository from "@/app/repository/repository-factory";
import Vue from "vue";

const PaymentApplicationRepository = Repository.get(
  "PaymentApplicationRepository"
);

/**
 * Zasilenie karty component
 */
export default {
  page: {
    title: "Zasilenie karty",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    Preloader,
    FormWizard,
    TabContent,
    prepaidCardApplicantDataStep,
    prepaidCardCompanyDataStep,
    prepaidCardDetailsDataStep,
    prepaidCardTransferDataStep,
    prepaidCardSummaryDataStep,
    TransferInfoSidebar,
  },
  data() {
    return {
      preloader: true,
      disabledBtn: false,
      clonedData: null,
      loggedUser: null,
      wizardError: false,
      finalModel: {
        applicantUserAccountId: "",
        applicantFirstNameField: "",
        applicantLastNameField: "",
        applicantEmailField: "",
        applicantRodoAccepted: false,
        companyId: "",
        companyNameField: "",
        companyAddressField: "",
        companyTaxNumberField: "",
        companyAccountSelectValueField: {
          index: null,
          name: "",
          description: "",
        },
        companyBusinessUnitCode: "",
        providerNameField: "",
        providerAddressField: "",
        providerTaxNumberField: "",
        providerAccountField: "",
        providerFormDataModified: false,
        prepaidCardSelectValueField: {},
        prepaidCardChecked: null,
        checkedPrepaidCardData: {
          isSettled: null,
          balance: null,
        },
        transferTitleField: "",
        transferGrossAmountField: null,
        transferCurrencySelectField: "",
        transferDateOfOperationField: "",
        transferTaxOpinionField: null,
        transferFeesAndChargesSelectField: "",
        transferApproverSelectField: {
          index: null,
          userAccountId: "",
          isActive: false,
          name: "",
          company: "",
          email: "",
          phone: "",
          registered: "",
        },
        transferAdditionalInfoField: "",
        transferRequestForConfirmationField: null,
        transferAttachmentsField: [],
      },
      title: "Zasilenie karty",
      items: [
        {
          text: "Strona główna",
          to: { name: "home" },
        },
        {
          text: "Zasilenie karty",
          active: true,
        },
      ],
    };
  },
  created() {
    this.loggedUser = this.currentUser();
    if (localStorage.getItem("cloneApplicationAction") !== null) {
      this.clonedData = JSON.parse(
        localStorage.getItem("cloneApplicationAction")
      );
      localStorage.removeItem("cloneApplicationAction");
    }
    setTimeout(() => {
      this.preloader = false;
    }, 1000);
  },
  methods: {
    ...authComputed,
    validateStep(name) {
      return this.$refs[name].validate();
    },
    mergePartialModels(model, isValid) {
      if (isValid) {
        this.wizardError = false;
        this.finalModel = Object.assign({}, this.finalModel, model);
      } else {
        this.wizardError = true;
      }
    },
    onComplete: function () {
      this.disabledBtn = true;
      this.preloader = true;

      let applicationData = {
        typeOfApplication: 4,
        typeOfTransfer: 1,
        providerName: this.finalModel.prepaidCardSelectValueField.nameOfCardholder,
        providerAddress: null,
        providerNip: this.finalModel.prepaidCardSelectValueField.cardholderNumber,
        providerBankAccountNumber: this.finalModel.prepaidCardSelectValueField.bankAccountNumber,
        changeDataManually: false,
        providerWithoutTaxNumber: false,
        applicantUserAccountId: this.finalModel.applicantUserAccountId,
        acceptorUserAccountId: this.finalModel.transferApproverSelectField.userAccountId,
        transfer: this.finalModel.transferTitleField,
        companyId: this.finalModel.companyId,
        grossAmount: this.finalModel.transferGrossAmountField,
        dateOfOperation: this.finalModel.transferDateOfOperationField,
        typeOfBankAccount: this.finalModel.companyAccountSelectValueField.index,
        additionalInfo: this.finalModel.transferAdditionalInfoField,
        prepaidCardId: this.finalModel.prepaidCardSelectValueField.prepaidCardId,
        confirmTransfer: this.finalModel.transferRequestForConfirmationField,
        cardNumber: this.finalModel.prepaidCardSelectValueField.cardNumber,
        isChecked: this.finalModel.prepaidCardChecked,
        isSettled: this.finalModel.checkedPrepaidCardData ? this.finalModel.checkedPrepaidCardData.isSettled : null,
        balance: this.finalModel.checkedPrepaidCardData ? this.finalModel.checkedPrepaidCardData.balance : null,
        attachments: this.finalModel.transferAttachmentsField,
      };
      PaymentApplicationRepository.create(applicationData)
        .then((response) => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "success",
            toast: false,
            position: "top",
            title: "SUKCES! ",
            text: "Wniosek o zasilenie karty został wysłany",
            showConfirmButton: false,
            timer: 3500,
            onClose: () => {
              this.$router.push({ name: "home" });
            },
          });
        })
        .catch((error) => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "error",
            toast: false,
            position: "top",
            title: "Błąd! ",
            text:
              "Nie można złożyć wniosku o zasilenie karty: " +
              error.response.data.message,
            showConfirmButton: false,
            timer: 3500,
            // onClose: () => {
            //   this.$router.push({ name: "home"})
            // }
          });
          console.log(error);
        });
    },
  },
  computed: {
    isApplicant() {
      return roleService.isApplicant();
    },
  },
};
</script>

<template>
  <Layout>
    <Preloader v-if="preloader" />
    <PageHeader :title="title" :items="items" />

    <div class="row" v-if="isApplicant">
      <div class="col-md-8">
        <div class="card shadow">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-md-8">
                <div class="media">
                  <img class="mr-3 mt-1" src="@/assets/images/si-pay-logo.png" alt="logo" height="30" />
                  <div class="media-body">
                    <h5 class="my-0">Wnioskujący : {{ loggedUser.firstName }} {{ loggedUser.lastName }}</h5>
                    <p class="small m-0 p-0">{{ loggedUser.email }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 text-right">
                <b-link class="btn btn-sm btn-warning" :to="{ name: 'home' }">Anuluj wniosek</b-link>
              </div>
            </div>
            <hr />
            <b-alert v-if="wizardError" variant="danger" class="mb-3" show>
              <strong>Błąd!</strong> Coś poszło nie tak, uzupełnij formularz poprawnie i spróbuj ponownie.
            </b-alert>
            <form-wizard shape="circle" color="#556ee6" errorColor="#ffffff" back-button-text="Powrót" next-button-text="Dalej" finish-button-text="Wyślij wniosek" @on-complete="onComplete">
              <!-- step 1 -->
              <!-- applicant -->
              <tab-content title="Wnioskujący" route="/prepaid-card-transfer/applicant" :before-change="()=>validateStep('prepaidCardApplicantDataStep')">
                <prepaidCardApplicantDataStep ref="prepaidCardApplicantDataStep" @on-validate="mergePartialModels"></prepaidCardApplicantDataStep>
              </tab-content>
              <!-- step 2 -->
              <!-- company -->
              <tab-content title="Dane firmy" route="/prepaid-card-transfer/company" :before-change="()=>validateStep('prepaidCardCompanyDataStep')">
                <prepaidCardCompanyDataStep ref="prepaidCardCompanyDataStep" @on-validate="mergePartialModels" :clonedData="clonedData"></prepaidCardCompanyDataStep>
              </tab-content>
              <!-- step 3 -->
              <!-- details -->
              <tab-content title="Szczegóły karty" route="/prepaid-card-transfer/details" :before-change="()=>validateStep('prepaidCardDetailsDataStep')">
                <prepaidCardDetailsDataStep ref="prepaidCardDetailsDataStep" @on-validate="mergePartialModels" :bucAsUuid="finalModel.companyId" :companyId="finalModel.companyId" :clonedData="clonedData"></prepaidCardDetailsDataStep>
              </tab-content>
              <!-- step 4 -->
              <!-- transfer -->
              <tab-content title="Dane do przelewu" route="/prepaid-card-transfer/transfer" :before-change="()=>validateStep('prepaidCardTransferDataStep')">
                <prepaidCardTransferDataStep ref="prepaidCardTransferDataStep" @on-validate="mergePartialModels" :companyId="finalModel.companyId" :isCardSettled="finalModel.checkedPrepaidCardData.isSettled" :prepaidCardId="finalModel.prepaidCardSelectValueField.prepaidCardId" :clonedData="clonedData"></prepaidCardTransferDataStep>
              </tab-content>
              <!-- step 5 -->
              <!-- summary -->
              <tab-content title="Podsumowanie" route="/prepaid-card-transfer/summary">
                <prepaidCardSummaryDataStep ref="prepaidCardSummaryDataStep" :summaryData="finalModel"></prepaidCardSummaryDataStep>
              </tab-content>
              <router-view></router-view>
            </form-wizard>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card shadow">
          <TransferInfoSidebar :finalModel="finalModel" :loggedUser="loggedUser" />
        </div>
        <!--
        <div class="card shadow">
          <div class="card-body">
            <h5 class="m-0 p-0">Warunki świadczenia usług</h5>
            <small class="text-muted">Format pliku PDF. Rozmiar 1.5MB</small><br />
            <button class="btn btn-success btn-block my-3">Pobierz plik</button>
          </div>
        </div>
        -->
      </div>
    </div>
  </Layout>
</template>
