<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/pl";
import Multiselect from "vue-multiselect";
import Switches from "vue-switches";
import vue2Dropzone from "vue2-dropzone";
import {
  required,
  maxLength,
  minLength,
  helpers,
} from "vuelidate/lib/validators";
import Repository from "@/app/repository/repository-factory";
import Client from "@/app/repository/axios-client";

const AcceptorRepository = Repository.get("AcceptorRepository");
const PaymentApplicationAttachmentRepository = Repository.get("PaymentApplicationAttachmentRepository");

const alphaNumAndSpecials = helpers.regex(
  "alphaNumAndSpecials",
  /^[a-zżźćńółęąśA-ZŻŹĆĄŚĘŁÓŃ0-9\s,./_-]*$/
);

export default {
  name: "transferDataStep",
  props: {
    companyId: {
      type: String,
      required: true,
    },
    prepaidCardId: {
      type: String,
      required: false,
    },
    isCardSettled: {
      type: Boolean,
      default: null,
      required: false,
    },
    clonedData: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
  },
  components: {
    DatePicker,
    Multiselect,
    Switches,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      inputLoading: false,
      transferApproverOptionsField: [],
      transferForm: {
        transferTitleField: "Zasilenie karty",
        transferGrossAmountField: null,
        transferDateOfOperationField: null,
        transferTaxOpinionField: null,
        transferFeesAndChargesSelectField: "",
        transferApproverSelectField: "",
        transferAdditionalInfoField: "",
        transferRequestForConfirmationField: null,
        transferAttachmentsField: [],
      },
      dropzoneOptions: {
        url: PaymentApplicationAttachmentRepository.getResourcePath(),
        thumbnailWidth: 150,
        paramName: "file",
        maxFilesize: 10, // 10MB
        addRemoveLinks: true,
        dictRemoveFile: "✕",
        headers: {
          Authorization: Client.defaults.headers.common["Authorization"],
          "Cache-Control": null,
          "X-Requested-With": null,
        },
      },
    };
  },
  validations() {
    return {
      transferForm: {
        transferTitleField: {
          required,
          alphaNumAndSpecials,
          minLength: minLength(4),
          maxLength: maxLength(50),
        },
        transferGrossAmountField: {
          required,
          maxLength: maxLength(13),
          valueGreaterZero: (value) => {
            if (value > 0) {
              return true;
            }
            return false;
          },
        },
        transferDateOfOperationField: {
          required,
        },
        transferApproverSelectField: {
          required,
        },
        transferAdditionalInfoField: {
          maxLength: maxLength(500),
        },
        transferRequestForConfirmationField: {},
        transferAttachmentsField: [],
      },
    };
  },
  created() {
    if (this.clonedData !== null) {
      this.transferForm.transferTitleField = this.clonedData.transfer;
      this.transferForm.transferGrossAmountField = parseFloat(this.clonedData.grossAmount);
      this.transferForm.transferRequestForConfirmationField = this.clonedData.confirmTransfer;
      this.transferForm.transferAdditionalInfoField = this.clonedData.additionalInfo;
    }
  },
  methods: {
    validate() {
      this.$v.transferForm.$touch();
      var isValid = !this.$v.transferForm.$invalid;
      this.$emit("on-validate", this.$data.transferForm, isValid);
      return isValid;
    },
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    },
    userLabel({ name, company, email }) {
      return `${name} ${company} ${email}`;
    },
    currencyLabel({ index, id, name }) {
      return `${index} ${id} ${name}`;
    },
    dropzoneAfterSuccess(file, response) {
      this.transferForm.transferAttachmentsField.push(response.uuid);
      file["tempUploadUuid"] = response.uuid;
    },
    dropzoneAfterRemove(file, error, xhr) {
      for (let i = 0; i < this.transferForm.transferAttachmentsField.length; i++) {
        if (!this.transferForm.transferAttachmentsField.attachmentId) {
          if (
            file.tempUploadUuid &&
            this.transferForm.transferAttachmentsField[i] === file.tempUploadUuid
          ) {
            this.transferForm.transferAttachmentsField.splice(i, 1);
          }
        }
      }
    },
    getAcceptorsForCompany() {
      this.inputLoading = true;
      let KRSparam = this.isCardSettled ? '' : '?onlyKRS=true';
      AcceptorRepository.getAllCardsAcceptors(this.companyId, KRSparam)
        .then((response) => {
          this.transferApproverOptionsField = response.data;
          // absence ad user mapping
          // this.transferApproverOptionsField = this.transferApproverOptionsField.map(v => ({...v, $isDisabled: v.isBusy == true}))
          if (this.clonedData !== null) {
            let userArr = this.transferApproverOptionsField.filter((obj) => {
              return (
                obj.userAccountId === this.clonedData.acceptorUserAccountId
              );
            });
            this.transferForm.transferApproverSelectField = userArr[0];
          }
          this.inputLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.inputLoading = false;
        });
    },
  },
  watch: {
    companyId: function (newVal, oldVal) {
      if (newVal !== "") {
        this.getAcceptorsForCompany();
      }
    },
    prepaidCardId: function (newVal, oldVal) {
      if (newVal !== "") {
        this.getAcceptorsForCompany();
      }
    },
    isCardSettled: function (newVal, oldVal) {
      if (newVal !== "") {
        this.getAcceptorsForCompany();
      }
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label for="transferDateOfOperationField">
          Data operacji
          <em class="text-danger">*</em>
        </label>
        <date-picker id="transferDateOfOperationField" v-model="transferForm.transferDateOfOperationField" value-type="format" format="YYYY-MM-DD" :editable="false" :first-day-of-week="1" :disabled-date="disabledBeforeToday" @input="$v.transferForm.transferDateOfOperationField.$touch()" :class="{ 'is-invalid': $v.transferForm.transferDateOfOperationField.$error, 'is-valid': !$v.transferForm.transferDateOfOperationField.$invalid && !$v.transferForm.transferDateOfOperationField.$error }" lang="pl" placeholder="Data w formacie YYYY-MM-DD" />
        <small id="transferDateOfOperationFieldHelp" class="form-text text-muted"><i class="mdi mdi-information-outline mr-1"></i>Data operacji nie jest datą realizacji przelewu</small>
        <div v-if="$v.transferForm.transferDateOfOperationField.$invalid" class="invalid-feedback">
          <span class="d-block" v-if="$v.transferForm.transferDateOfOperationField.$invalid && !$v.transferForm.transferDateOfOperationField.required">Pole jest wymagane!</span>
        </div>
      </div>
      <div class="form-group">
        <label for="transferTitleField">
          Tytuł przelewu
          <em class="text-danger">*</em>
        </label>
        <input id="transferTitleField" v-model.trim="transferForm.transferTitleField" @input="$v.transferForm.transferTitleField.$touch()" class="form-control" :class="{ 'is-invalid': $v.transferForm.transferTitleField.$error, 'is-valid': !$v.transferForm.transferTitleField.$invalid && !$v.transferForm.transferTitleField.$error }" type="text" name="transferTitleField" placeholder="Wpisz tytuł przelewu" value required />
        <div v-if="$v.transferForm.transferTitleField.$error" class="invalid-feedback">
          <span class="d-block" v-if="$v.transferForm.transferTitleField.$error && !$v.transferForm.transferTitleField.required">Pole jest wymagane!</span>
          <span class="d-block" v-if="$v.transferForm.transferTitleField.$error && !$v.transferForm.transferTitleField.maxLength">Przekroczona dozwolona ilość znaków!</span>
          <span class="d-block" v-if="$v.transferForm.transferTitleField.$error && !$v.transferForm.transferTitleField.minLength">Zbyt mała ilość znaków!</span>
          <span class="d-block" v-if="$v.transferForm.transferTitleField.$error && !$v.transferForm.transferTitleField.alphaNumAndSpecials">Wpisano niedozwolone znaki!</span>
        </div>
      </div>
      <div class="form-group">
        <label for="transferGrossAmountField">
          Kwota przelewu
          <em class="text-danger">*</em>
        </label>
        <currency-input id="transferGrossAmountField" v-model.trim="transferForm.transferGrossAmountField" :value-range="{min:0.0, max:99999999.99}" :allow-negative="false" :distraction-free="false" class="form-control" @input="$v.transferForm.transferGrossAmountField.$touch()" :class="{ 'is-invalid': $v.transferForm.transferGrossAmountField.$error, 'is-valid': !$v.transferForm.transferGrossAmountField.$invalid && !$v.transferForm.transferGrossAmountField.$error }" placeholder="Wpisz kwotę przelewu w PLN" value />
        <div v-if="$v.transferForm.transferGrossAmountField.$error" class="invalid-feedback">
          <span class="d-block" v-if="$v.transferForm.transferGrossAmountField.$error && !$v.transferForm.transferGrossAmountField.required">Pole jest wymagane!</span>
          <span class="d-block" v-if="$v.transferForm.transferGrossAmountField.$error && !$v.transferForm.transferGrossAmountField.maxLength">Przekroczona dozwolona ilość znaków!</span>
          <span class="d-block" v-if="$v.transferForm.transferGrossAmountField.$error && !$v.transferForm.transferGrossAmountField.valueGreaterZero">Podana wartość jest niepoprawna!</span>
        </div>
      </div>
      <div class="form-group" :class="{ 'is-invalid' : $v.transferForm.transferApproverSelectField.$error }">
        <label for="transferApproverSelectField">
          Wybierz akceptującego wniosek
          <em class="text-danger">*</em>
        </label>
        <multiselect id="transferApproverSelectField" v-model.trim="transferForm.transferApproverSelectField" :options="transferApproverOptionsField" :custom-label="userLabel" track-by="email" @input="$v.transferForm.transferApproverSelectField.$touch()" :class="{ 'is-invalid': $v.transferForm.transferApproverSelectField.$error, 'is-valid': !$v.transferForm.transferApproverSelectField.$invalid && !$v.transferForm.transferApproverSelectField.$error }" name="transferApproverSelectField" placeholder="Wybierz użytkownika z listy" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required :disabled="inputLoading">
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }}</span>
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.firstName }} {{ props.option.lastName }}</span>
              <br />
              <span class="option__small small text-dark">
                <span v-if="props.option.isBusy" class="badge badge-pill badge-soft-danger font-size-10" >Na urlopie</span>
                {{ props.option.email }} {{ props.option.company }}
              </span>
            </div>
          </template>
          <template slot="clear">
            <div class="multiselect__loading"><i v-if="inputLoading" class="el-icon-loading mr-1"></i></div>
          </template>
          <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
        </multiselect>
        <template v-if="$v.transferForm.transferApproverSelectField.$error">
          <small class="text-danger">Pole jest wymagane!</small>
        </template>
        <small v-if="transferApproverOptionsField.length == 0 && !isCardSettled" class="form-text text-muted"><i class="mdi mdi-information-outline mr-1"></i>Brak zdefiniowanych akceptujących karty nierozliczone dla spółki.</small>
      </div>
      <div class="form-group">
        <label for="transferAdditionalInfoField">Dodatkowa informacja</label>
        <textarea id="transferVatAmountField" v-model.trim="transferForm.transferAdditionalInfoField" @input="$v.transferForm.transferAdditionalInfoField.$touch()" class="form-control" :class="{ 'is-invalid': $v.transferForm.transferAdditionalInfoField.$error, 'is-valid': !$v.transferForm.transferAdditionalInfoField.$invalid && !$v.transferForm.transferAdditionalInfoField.$error }" type="text" placeholder="Wpisz dodatkowe uwagi do wniosku" value></textarea>
        <div v-if="$v.transferForm.transferAdditionalInfoField.$invalid" class="invalid-feedback">
          <span class="d-block" v-if="$v.transferForm.transferAdditionalInfoField.$invalid && !$v.transferForm.transferAdditionalInfoField.maxLength">Przekroczona dozwolona ilość znaków!</span>
        </div>
      </div>
      <div class="d-flex justify-content-start">
        <switches class="mt-2" v-model="transferForm.transferRequestForConfirmationField" type-bold="false" :color="transferForm.transferRequestForConfirmationField == 1 ? 'success' : 'primary'"></switches>
        <label class="mt-1 ml-3"><span class="mr-2">Potwierdzenie wykonania przelewu:</span>
          <span v-if="transferForm.transferRequestForConfirmationField" class="badge badge-pill badge-soft-success font-size-14">TAK</span>
          <span v-else class="badge badge-pill badge-soft-danger font-size-14">NIE</span>
        </label>
      </div>
      <div class="form-group">
        <label for="dropzone">Załączniki</label>
        <vue-dropzone id="dropzone" ref="myVueDropzone" :options="dropzoneOptions" @vdropzone-success="dropzoneAfterSuccess" @vdropzone-removed-file="dropzoneAfterRemove" :useCustomSlot=true >
          <div class="dropzone-custom-content dz-message">
            upuść pliki tutaj lub kliknij, aby przesłać
          </div>
        </vue-dropzone>
      </div>
    </div>
  </div>
</template>
